import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  user: {
    auth_date: number;
    name: string;
    hash: string;
    id: number;
    photo_url: string;
    username: string;
  };
}

interface PayloadInterface {
  auth_date: number;
  username: string;
  hash: string;
  id: number;
  photo_url: string;
  first_name: string;
}

const initialState: ProfileState = {
  user: {
    name: "",
    hash: "",
    id: 0,
    photo_url: "",
    username: "",
    auth_date: 0,
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<PayloadInterface>) => {
      if (action.payload) {
        state.user.name = action.payload.first_name;
        state.user.id = action.payload.id;
        state.user.auth_date = action.payload.auth_date;
        state.user.hash = action.payload.hash;
        state.user.photo_url = action.payload.photo_url;
        state.user.username = action.payload.username;
      }
    },
  },
});

export const { login } = profileSlice.actions;
export default profileSlice.reducer;
