import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { AboutModal } from "./AboutModal";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { afAuth } from "../redux/slices/forDonateSlice.ts";
import { login } from "../redux/slices/profileSlice.ts";
import { RootState } from "../redux/store.ts";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const profile = useSelector((state: RootState) => state.profile.user);
  const dispatch = useDispatch();

  const id = searchParams.get("id");
  const username = searchParams.get("username");
  const first_name = searchParams.get("first_name");
  const photo_url = searchParams.get("photo_url");
  const auth_date = searchParams.get("auth_date");
  const hash = searchParams.get("hash");

  const tgAuth = (e: any) => {
    axios
      .post(`https://api.fish-battle2023.ru/api/login`, {
        user_id: e.id,
        user_name: e.username,
        first_name: e.first_name,
      })
      .then(function (response) {
        dispatch(afAuth(response.data.token));
        dispatch(login(e));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (id) {
      tgAuth({ id, username, first_name, photo_url, auth_date, hash });
      searchParams.delete("id");
      searchParams.delete("username");
      searchParams.delete("first_name");
      searchParams.delete("photo_url");
      searchParams.delete("auth_date");
      searchParams.delete("hash");
    }
  });
  return (
    <>
      <Box borderBottom="1px solid #DDE1E6" mb={12} py={5}>
        <Container maxW="container.lg">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap={7}>
              <Image src={logo} w={50} h={50} />
              <Text
                cursor="pointer"
                _hover={{ color: "purple" }}
                transition=".3s"
                fontSize={16}
                onClick={onOpen}
              >
                О нас
              </Text>
            </Flex>
            <Flex alignItems="center" w="132px" justifyContent="space-between">
              <ColorModeSwitcher />

              {profile?.name ? (
                <Menu>
                  <MenuButton>
                    <Avatar
                      size="md"
                      name={profile?.name}
                      src={photo_url || profile?.photo_url}
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuGroup
                      title={profile.name}
                      fontWeight={400}
                      fontSize={16}
                    >
                      <MenuGroup title={`@${profile.username}`} fontSize={16}>
                        <MenuItem
                          onClick={() => {
                            dispatch(
                              login({
                                first_name: "",
                                hash: "",
                                id: 0,
                                photo_url: "",
                                username: "",
                                auth_date: 0,
                              })
                            );
                            dispatch(afAuth({ token: "" }));
                          }}
                        >
                          Выйти
                        </MenuItem>
                      </MenuGroup>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              ) : (
                <Button
                  colorScheme="purple"
                  size="sm"
                  onClick={() =>
                    // @ts-ignore
                    window.Telegram.Login.auth({ bot_id: 7150069836 }, tgAuth)
                  }
                >
                  Войти
                </Button>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
      <AboutModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
