// @ts-nocheck
import { AspectRatio } from "@chakra-ui/react";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";

export const Translation = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [url, setUrl] = useState("https://develop-umk.ru/obs_stream.m3u8");
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url;
      }
    }
  }, [url]);

  return (
    <>
      <AspectRatio maxW="container.lg" maxH={500} ratio={1}>
        <video ref={videoRef} controls />
      </AspectRatio>
    </>
  );
};
