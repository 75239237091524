import { combineReducers } from "redux";
import profileReducer from "./slices/profileSlice";
import afterAuthSliceReducer from "./slices/forDonateSlice";

const rootReducer = combineReducers({
  profile: profileReducer,
  user_key: afterAuthSliceReducer,
});

export default rootReducer;
