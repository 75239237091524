import { Box, Container, Flex, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <>
      <Box borderTop="1px solid #DDE1E6" mt={24} pt={6}>
        <Container maxW="container.xxl" px={{ base: 4, md: 8, lg: 24 }}>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              gap={7}
              flexDirection={{ base: "column", md: "row", lg: "row" }}
            >
              <Text fontSize={16} color={"grey"}>
                created by @friendlyfish
              </Text>
              <Text>Политика конфиденциальности</Text>
              <Text>
                <Text as="span" px={4}>
                  Почта для связи
                </Text>
                <Text
                  as="a"
                  color={"rgb(128, 90, 213)"}
                  href="mailto:bitvafish2023@mail.ru"
                >
                  bitvafish2023@mail.ru
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
