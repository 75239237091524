import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Textarea,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { RootState } from "../redux/store.ts";
import { useSelector, useDispatch } from "react-redux";
import { afAuth } from "../redux/slices/forDonateSlice.ts";
import { login } from "../redux/slices/profileSlice.ts";

interface DonutModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const DonutModal = ({ isOpen, onClose }: DonutModalProps) => {
  const [available, setAvailable] = useState<Array<object>>([]);
  const [isChecked, setCheked] = useState<string>("Tinkoff");

  const [isLoading, setLoading] = useState<boolean>(false);

  const user_id = useSelector((state: RootState) => state.profile.user.id);
  const [user_amount, setUserAmount] = useState<number>(250);

  const user_key = useSelector(
    (state: RootState) => state.user_key.afterAuth.token
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.fish-battle2023.ru/api/available`
        );
        const res: Array<object> = [];

        for (let key in response.data) {
          let obj: Record<string, string> = {};
          obj[key] = response.data[key];

          res.push(obj);
        }
        setAvailable(res);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const tgAuth = (e: any) => {
    axios
      .post(`https://api.fish-battle2023.ru/api/login`, {
        user_id: e.id,
        user_name: e.username,
        first_name: e.first_name,
      })
      .then(function (response) {
        dispatch(afAuth(response.data.token));
        dispatch(login(e));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const sendDonate = (e: any) => {
    setLoading(true);
    axios
      .post(
        `https://api.fish-battle2023.ru/api/addDonate`,
        {
          tg_id: user_id,
          amount: user_amount,
          method: isChecked,
        },
        { headers: { Authorization: `Bearer ${user_key}` } }
      )
      .then(function (response) {
        window.location.href = response.data.payment_url.transaction.paymentUrl;
        onClose();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {user_id !== 0 ? (
        <ModalContent>
          <ModalHeader>Поддержать проект</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading size="sm" my={2}>
              Способ оплаты
            </Heading>
            <RadioGroup value={isChecked} onChange={setCheked}>
              {available.map((obj, id) => (
                <>
                  {Object.keys(obj).map((key) => (
                    <Radio
                      key={id}
                      variant="outline"
                      color="orange"
                      m={2}
                      defaultChecked
                      value={`${key}`}
                    >
                      {obj[key as keyof typeof obj]}
                    </Radio>
                  ))}
                </>
              ))}
            </RadioGroup>

            <Heading size="sm" mb={2}>
              Сумма доната
            </Heading>
            <NumberInput
              defaultValue={250}
              min={200}
              step={50}
              clampValueOnBlur={false}
              onChange={(value) => setUserAmount(+value)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Heading size="sm" my={2}>
              Комментарий
            </Heading>
            <Textarea
              placeholder="Здесь вы можете оставить комментарий"
              resize={"none"}
              h="150px"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={sendDonate}
              isLoading={isLoading}
            >
              Поддержать
            </Button>

            <Button variant="ghost" onClick={onClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent>
          <ModalHeader>
            Поддержать проект может только авторизованный пользователь!
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              colorScheme="purple"
              width={"100%"}
              size="md"
              onClick={() =>
                // @ts-ignore
                window.Telegram.Login.auth({ bot_id: 6743055068 }, tgAuth)
              }
            >
              Авторизоваться
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};
