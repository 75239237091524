import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AfterAuth {
  afterAuth: {
    token: string;
  };
}

const initialState: AfterAuth = {
  afterAuth: {
    token: "",
  },
};

interface PayloadInterface {
  token: string;
}
export const afterAuthSlice = createSlice({
  name: "user_key",
  initialState,
  reducers: {
    afAuth: (state, action: PayloadAction<PayloadInterface>) => {
      state.afterAuth.token = action.payload.token;
    },
  },
});

export const { afAuth } = afterAuthSlice.actions;
export default afterAuthSlice.reducer;
