import { Box, Container } from "@chakra-ui/react";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";

export const Layout = () => {
  return (
    <Box pb={5} overflowX={"hidden"}>
      <Header />
      <Container maxW="container.lg">
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};
